.checkbox-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0.2em;
}

.ui.dropdown > .text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.filters-container {
    overflow-y: hidden;
    overflow-x: hidden;
    height: calc(100vh);
}
