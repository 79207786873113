.cookie-banner {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 14000;
  background-color: white;
  border-top: 2px solid grey;
}

.info-text {
  color: #0645AD;
  // text-decoration: underline;
  cursor: pointer;
}

.maintenance-modal {
  z-index: 8000;
}

div#root div.App div.static-content table tbody tr.highlight {
  background-color: rgba(85,166,186, 0.2);
}

.hover {
  position: absolute;
  right: 10px;
  top: 50%;  /* position the top  edge of the element at the middle of the parent */
  transform: translate(0%, -50%);  
  transition-delay: 3s;
  transition: opacity 3s;
  opacity: 1;
}

.no-hover {
  opacity: 0;
  position: absolute;
}

.newsletter-popup {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  //opacity: 0.3;
  width: 100vw;
  height: 100vh;
  z-index: 999;

  &-content {
    position: absolute;
    width: 500px;
    height: 300px;
    top: calc(50vh - 150px);
    left: calc(50vw - 250px);
    border: 1px solid #EEE;
    background: white;
  }

  &-close-button {
    position: absolute;
    top: calc(50vh - 150px);
    right: calc(50vw - 250px);
    z-index: 1000;
    background-color: white !important;
    //TODO: fix padding issue
    padding: 0;

    &:hover {
      background-color: lightgray !important;
    }


  }

}