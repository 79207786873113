.ui.fluid.input>input {
    width: 40% !important;
}

.ui.input>input {
    padding: 5px;
}

.space-between {
    display: flex;
    justify-content: space-between;
}

.input-seperate {
    width: 11ch;
    padding: 0 1px;
}

/*Component CSS*/

.track-style{
    position: absolute;
    height: 5px;
    z-index: 1;
    border-radius: 7px;
    cursor: pointer;
}

/*TooltipRail Style*/

.rail-style{
    position: absolute;
    width: 100%;
    height: 40px;
    top: -13px;
    border-radius: 7px;
    cursor: pointer;
    opacity: 0.3;
    z-index: 300;
}

.rail-center-style{
    position: absolute;
    width: 100%;
    height: 5px;
    border-radius: 7px;
    cursor: pointer;
    pointer-events: none;
    background-color: #eaeaea;
}

/* Sub category: tooltip styles*/
.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted #222;
    margin-left: 22px;
}

.tooltip .tooltiptext {
    width: 100px;
    background-color: #222;
    color: #fff;
    opacity: 0.8;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -60px;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #222 transparent transparent transparent;
}

/* Tick Styles */
.tick-style{
    position: absolute;
    margin-top: 17px;
    width: 1px;
    height: 5px;
    background-color: rgb(200,200,200);
}

.tick-center-style{
    position: absolute;
    margin-top: 25px;
    font-size: 10px;
    text-align: center;
}

/* Handle props */
.active-handle-style{
    position: absolute;
    margin-left: -11px;
    margin-top: -35px
}

.handle-style{
    position: absolute;
    margin-left: -11px;
    margin-top: -6px;
    z-index: 2;
    width: 18px;
    height: 18px;
    cursor: pointer;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
}