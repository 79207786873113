.main-statistic-title {
  font-weight: 400;
  font-size: 1em;
  line-height: 1em;
}

.main-statistic {
  font-weight: 400;
  font-size: 1em;
  line-height: 1em;
}

.secondary-statistic-title {
  font-weight: 400;
  font-size: 1em;
  line-height: 1em;
}

.secondary-statistic {
  font-weight: 400;
  font-size: 1em;
  line-height: 1em;
  text-align: center;  
  color: #455A64;
}

.stats-container {
  flex: 0 1
}