.mapContainer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.map {
  flex: 1 1;
  position: relative;
}

.map:focus {
  outline:0;
}

body {
  margin: 0px;
}

.info {
  border: 1px solid rgba(34,36,38,.15);
  border-radius: 4px;
  background: #fff;
}

div.slide-pane__content {
  z-index: 100;
}

div.ReactModal__Content.ReactModal__Content--after-open.slide-pane.slide-pane_from_right.some-custom-class {
  z-index: 100;
}

div.slide-pane__header {
  z-index: 100;
}

div.ReactModal__Overlay.ReactModal__Overlay--after-open.slide-pane__overlay.some-custom-overlay-class {
  z-index: 100;
}

.map-wrapper {
  position: relative;
}

.pin-popup {
  outline: none;
}

.popup-content {
  width: 460px;
  @media screen and (max-width: 1200px){
    width: 300px;
  }

  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  background: #fff;
  font-style: normal;
  text-align: start;
}

.mapboxgl-popup-content {
  padding: 0 !important;
}

.country-popup {
  &-content {
    width: 350px;
    @media screen and (max-width: 1200px){
      width: 300px;
    }
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    background: #fff;
    font-style: normal;
    text-align: start;
  }

  &-title {
    font-weight: bold;
    font-size: 18px;
    flex: 1;
    width: 100%;

  }

  &-heading {
    font-weight: 600;
    font-size: 12px;
    width: 62%;
  }

  &-text {
    font-weight: normal;
    font-size: 12px;
    width: 34.75%;

  }

  &-header {
    font-weight: bold;
    font-size: 14px;
    width: 100%;
  }
}

.disable-popup {
  display:none !important;
}