.legend {
  font-size: 12px;
  line-height: 12px;
  position: absolute;
  width: 180px;
  bottom: 10px;
  right: 10px;
  z-index: 500;
}

.legend-container { 
  flex-direction: column;
  align-items: flex-start;
  padding: 5px;
}

.legend-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0.2em;
  width: 95%;
}

label {
  margin: 0;
  padding: 5px;
  /*white-space: nowrap;*/
  overflow: hidden;
}

.legend-icon {
  margin-right: 3px;
  flex-shrink: 0 !important;
  height: 15px !important;
  width: 15px !important;
}

.legend-checkbox {
  margin-left: auto;
}

.legend-label {
  margin-right: 1em;
}

.mobile-text {
  text-align: start;
  margin-left: -5px;
  font-size: 12px;
}

.legend i,
.legend-mobile i {
  /* width: 6vw; */
  width: 20px;
  height: 20px;
  float: left;
  opacity: 0.7;
}

.bin {
  width: 14.2857%;
}

.circleBase {
  border-radius: 50%;
}

h4.legend-title:hover {
  position: relative;
}
h4.legend-title span {
  display: none;
}
h4.legend-title:hover span {
  display: flex;
  margin: 10px;
  position: absolute;
  width: 300px;
  border-radius: 5px;
  bottom: 10px;
  padding: 1em;
  font-weight: 400;
  display: flex;
  padding: 0.5em 0;
  font-size: 0.875em;
  background-color: #fff;
  color: #000;
  -webkit-box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15);
  box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15);
}