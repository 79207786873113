/* Primary Colors */
$sero-blue: #55A6BA;
$sero-dark-blue: #364E60;
$sero-green: #47C2B1;
$white: #FFFFFF;

.sero-blue { color: $sero-blue}
.sero-dark-blue { color: $sero-dark-blue}
.sero-green { color: $sero-green}
.white { color: $white}


/* Secondary Colors */
/*  Greys */
$light-blue-grey: #C9D6DF;
$light-grey: #EAEAEA;
$grey: #888888;
$dark-grey: #212121;

/*  Highlights */
$banana: #FFBB49;
$spicy: #E15759;
$aqua: #0275FF;
$stone-cold-steve-austin: #094180;