.publications{
  &-card{
    height: 280px;

    &-image{
      width: 100%;
      border-radius: 5px;
      position:relative;
      object-fit: contain;
      height: 100px;
      align-items: flex-start;
    }

    &-date{
      font-size: 0.9em;
      font-weight: 500;
    }

    &-title {
      line-height: 1.3em;
      font-size: 1.2em;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    &-no-img {
      height: 120px;
    }


    &-image-overlay {
      font-size: 20px;
      font-weight: 400;
      color: white;
    }

    &-image:hover > .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      border-radius: 5px;
      background-color: #000;
      justify-content: center;
      text-decoration: none;
      transition-delay: 3s;
      transition: opacity 500ms;
      opacity: 0.8;
    }

    &-image > .overlay {
      position: absolute;
      justify-content: center;
      opacity: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.private-reports-card {
  height: 160px;
}

// CSS trick to get arrows to show
// https://stackoverflow.com/questions/28998536/slick-slider-next-arrows-not-showing
.slick-prev:before, .slick-next:before{
  color:black;
  font-size: 40px; 
  line-height: 1;
  font-family: "slick"
}
.slick-prev:before { content: "‹"; }
.slick-next:before { content: "›"; }

.slick-dots {
  position: relative;
  margin-top: -32px;
}

// https://github.com/akiran/react-slick/issues/1571
.publications-slider-container {
  max-width: 80vw;
  width: 100%;
  align-self: center;
}

.publication-menu{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.publication-menu-item {
  box-sizing: border-box;
  padding: 0 0.5rem;
  flex: 1;
}

.publication-meta {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.publication-item {
  -webkit-box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
  box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;  box-sizing: border-box;
  padding: 1rem;
  border-radius: 5px;
}

.publication-link{
  color: #0066cc
}

.publication-link:hover {
   color: #0000B8 !important;
}

.research-article-scroll-container {
  max-height: 500px;
  overflow-y: scroll;
  padding: 1rem;
}