.text-lead{
  font-size: 16.8px;
  line-height: 150%;
}

.text-default {
  font-size: 14px;
  line-height: 150%;
}

.text-small {
  font-size: 12px;
  line-height: 150%;
}

h1, h2, h3, h4, h5 {
  margin: 0;
  line-height: 110%;
}

h1 {
  font-size: 34.8px;
}

h2 {
  font-size: 29px;
}

h3 {
  font-size: 24.2px;
}

h4 {
  font-size: 20.2px;
}

h5 {
  font-family: "Open Sans";
  font-size: 14px;
}

.subheading {
  font-size: 14px;
  font-weight: 600;
}

.extra-bold {
  font-weight: 800;
}