@import './variables.scss';
@import "animations";
@import "typography";

.App {
    flex: 1 1 auto;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 107);
}

.App-header {
    background-color: $sero-dark-blue;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 1.25;
    flex: 1 1;
    color: lightgrey;
    font-weight: 300;
    height: 50px;
    z-index: 10000;
}

.app-header-font {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
}

.nav__item--active a{
    font-weight: 700 !important;
    color: white !important;
    font-size: 14px;
    line-height: 18px;
    font-style: normal;
}

.nav__item a{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: white !important;


}

.nav__item a:hover {
    color: #C4C4C4 !important;
    //color: $sero-blue !important;

    text-decoration: none;
}

.nav__item--accordion {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: white !important;
}

.nav__item > .ui.dropdown {
    display: flex;
    align-items: center;
    height: 19px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: white !important;

}

.ui.accordion .title{
    //font-family: 'Open Sans', sans-serif !important;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: white !important;
}

.dropdown__icon{
    transform: rotate(270deg) !important;
}

.dropdown__icon--active{
    transform: rotate(180deg) !important;
}

.download-data-btn.ui.button {
    background-color: $stone-cold-steve-austin;
    a {
        color: white;
    }
    margin: 0;
}

.clear-filters-btn.ui.button {
    border-color: $stone-cold-steve-austin;
    background-color: white;
    border-width: thin;
    border-style: solid;
    width: 100%;
    color: $stone-cold-steve-austin;
}

.cite-link {
    color: #0066CC;
    cursor: pointer;
    &:hover {
        color: #2f00a1;
    }
}

.italics{
    font-style: italic;
}

.bold-text {
    font-weight: bold;
}

.left-item {
    justify-content: flex-start;
}

.middle-item {
    justify-content: center;
}

.right-item {
    justify-content: flex-end;
}

.App-tabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    font-size: 1.25em;
}

.bold {
    border-bottom: 3px solid white;
}

.bold a {
    font-weight: 500;
    margin-bottom: -3px;
    color: white;
}

.regular a, .regular div{
    font-weight: 500;
    color: lightgrey;
}

.flex {
    display: flex;
    flex-wrap: wrap;
    max-height: 100%;
}

.column {
    flex-direction: column;
}

.fit {
    flex: 0 1;
}

.fill {
    flex: 1 1;
}

.start-item {
    justify-content: start;
    align-content: center;
}

.end-item {
    justify-content: end;
    align-content: center;
}

.right {
    text-align: end;
    justify-content: flex-end;
}

.center {
    text-align: center;
    justify-content: center;
}

.middle {
    justify-content: center;
}

.left {
    text-align: start;
    justify-content: flex-start;
}

.top {
    align-content: flex-start;
}

.space-evenly {
    justify-content: space-evenly;
}

.section-title {
    font-weight: bold;
    font-size: 1em;
    line-height: 19px;
    color: #455a64;
}

.center-item {
    justify-content: center;
    align-items: center;
    align-content: center;
}

.App-container {
    flex-direction: column;
    flex-wrap: wrap;
    display: flex;
}

.App-title > a {
    font-family: 'Roboto', sans-serif !important;
    color: white;
    font-size: 1.7em;
    font-weight: 200;
    cursor: pointer;
}

.regular a, .regular div {
    font-weight: 200;
    color: lightgrey;
}



.popup{
    &-title {
        font-weight: bold;
        font-size: 16px;
        flex: 1;
        width: 100%;
    }

    &-subtitle {
        font-weight: normal;
        font-size: 12px;
        width: 100%;
    }

    &-heading {
        font-weight: 600;
        font-size: 12px;
        width: 34%;
    }

    &-text {
        font-weight: normal;
        font-size: 12px;
        width: 61.75%;
    }

    &-header {
        font-size: 1.40em;
        line-height: 1.2;
        margin-bottom: 0.25em;
        color: #333333;
        display:flex;
        justify-content: flex-start;
        font-weight: 600;
    }

    &-risk-tag{
        padding: 2px 5px;
        background-color: #C4C4C4;
        font-size: 12px;
        font-weight: 600;
        text-align: center;
        width: 100%

    }

    &-risk {
        &-High{
            background-color: #FEDBD7;
            //width: 109px;
        }
        &-Moderate{
            background-color: #FFF8D3;
            //width: 139px;
        }
        &-Low{
            background-color: #D4F7F6;
            //width: 106px;
        }
    }

    &-seroprev-level{
        display: flex;
        justify-content: space-between;
        background-color: #EAEAEA;
        font-size: 12px;
        font-weight: 600;
        width: 100%
    }

    &-section{
        padding: 5px 10px;
    }

}

.icon {
    margin: 3px 5px;
}

.cursor {
    cursor: pointer;
}

.modal {
    height: auto;
    top: auto;
    left: auto;
    bottom: auto;
    right: auto;
}

.modal-text {
    margin-left: 16px;
    margin-right: 16px;
}

.modal-text p {
    font-size: 20px;
}

.modal-text-mobile {
    margin-left: 16px;
    margin-right: 16px;
}

.modal-content {
    border: none
}

.checkbox {
    width: 1.25em;
    height: 1.25em;
}

.dropdown a {
    color: #333333 !important;
    &:hover {
        color: #333333 !important;
    }
}

.link {
    text-decoration: underline;
    font-weight: 900;
}

.full-width {
    width: 100%;
}