.sidebar-container {
  border: none;
  overflow-y: auto;
  overflow-x: hidden;
  display: block;
  padding: 5%;

  &::-webkit-scrollbar {
    width: 3px;
  }
}

.left-sidebar {
  font-size: 14px;
  line-height: 21px;
}