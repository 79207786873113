.biblio-digest {
  &-card {
    height: 350px;
    width: 100% !important;
    max-width: 350px !important;

    &-text {
      font-size: 0.9em;
      font-weight: 500;
    }

    &-text-bold {
      font-size: 0.9em;
      font-weight: 1000;
    }

    &-title {
      margin: 0px !important;
    }

    &-row {
      flex-direction: row;
      justify-content: space-between;
      display: flex;
    }
  }
}
